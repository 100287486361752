/**
 * User: denisverstov
 * Date: 15/09/2020
 * Time: 22:20
 */

const ExportJsonExcel = require('js-export-excel');

/**
 *
 * @param {Object} data
 * @param {string} [fileName="Stats"]
 * @param {(Object | Array)} customLabels
 * @param {(Object | Array)} customDataSheet
 * @param {Array} columnWidths
 */

const exportToExcel = (data, fileName = 'Stats', customLabels = null, customDataSheet = null, columnWidths = [16]) => {
  let { labels, datasets } = data;

  const dataSheet = customDataSheet || collectData(labels, datasets);
  labels = customLabels || labels;

  const option = {
    fileName: fileName,
    datas: [
      {
        sheetData: dataSheet,
        sheetName: 'sheet',
        sheetFilter: labels,
        sheetHeader: labels,
        columnWidths,
      },
    ],
  };

  const toExcel = new ExportJsonExcel(option); // new
  toExcel.saveExcel();
};

/**
 *
 * @param {Array} labels
 * @param {Array} datasets
 * @returns {[]}
 */

const collectData = (labels, datasets) => {
  const dataSheet = [];

  datasets.forEach(data => {
    const obj = { Retailer: data.label };
    data.data.forEach((info, index) => {
      obj[labels[index]] = info;
    });
    dataSheet.push(obj);
  });

  return dataSheet;
};

export default {
  collectData,
  exportToExcel,
};
